import React, { useState } from 'react';
import Button from 'widgets/Button';
import PropTypes from 'prop-types';
import styles from './index.scss';
import TestInviteModal from './TestInviteModal';

function TestInvite({ id, updateTestList }) {
	const [isOpenModal, setIsOpenModal] = useState(false);
	const openInviteModal = () => {
		setIsOpenModal(true);
	};
	const closeTestInviteModal = () => {
		setIsOpenModal(false);
	};
	return (
		<div className={styles.inviteContainer}>
			<Button
				onClick={openInviteModal}
				variant="secondary"
				className={styles.openInviteCta}
			>
				Invite
			</Button>
			{isOpenModal && (
				<TestInviteModal
					id={id}
					closeModal={closeTestInviteModal}
					updateTestList={updateTestList}
					isActive={isOpenModal}
				/>
			)}
		</div>
	);
}
TestInvite.propTypes = {
	id: PropTypes.any,
	updateTestList: PropTypes.func
};

export default TestInvite;
