module.exports = {
	questions: {
		route: '/questions',
		roles: ['ROLE_TEACHER', 'ROLE_ADMIN', 'ROLE_EXTERNAL_SOURCER']
	},
	loginPage: {
		route: '/login'
	},
	createQuestion: {
		route: '/question-bank/create/:type/',
		roles: ['ROLE_TEACHER', 'ROLE_ADMIN', 'ROLE_EXTERNAL_SOURCER']
	},
	editQuestion: {
		route: '/question-bank/edit/:type/:id',
		roles: ['ROLE_TEACHER', 'ROLE_ADMIN']
	},
	testListPage: {
		route: '/test/',
		roles: ['ROLE_TEACHER', 'ROLE_ADMIN']
	},
	test: {
		route: '/student/test/:gid',
		roles: ['ROLE_TEACHER', 'ROLE_ADMIN', 'ROLE_STUDENT']
	},
	invitation: {
		route: '/invitations',
		roles: ['ROLE_TEACHER', 'ROLE_ADMIN', 'ROLE_STUDENT']
	},
	signUp: {
		route: '/signup',
	}
};
