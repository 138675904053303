import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './index.scss';

const SecondaryHeader = ({active, children }) => {
	return (
		<div className={cx(styles.root, { [styles.inactive]: !active })}>
			{children}
		</div>
	);
};

SecondaryHeader.propTypes = {
	active: PropTypes.bool,
	children: PropTypes.element
};

export default SecondaryHeader;
