import React, { useState } from 'react';
import styles from './index.scss';
import PropTypes from 'prop-types';
import Modal from 'shared/Modal';
import Button from 'widgets/Button';
import Input from 'widgets/Input';
import ConditionalRender from 'shared/ConditionalRender';
import DateRangePicker from 'shared/DateRangePicker';
import { inviteToTest } from 'apis/testServiceApis';
import { bindActionCreators } from 'redux';
import { showSnackBar } from 'actions/snackBar';
import { setLoader } from 'actions/common';
import { connect } from 'react-redux';

function TestInviteModal({
	id,
	closeModal,
	showSnackBar,
	setLoader,
	isActive
}) {
	const InviteModalHeader = () => {
			return (
				<div className={styles.headerContainer}>
					<span>Invite Cohorts</span>
				</div>
			);
		},
		[startDate, setStartDate] = useState(),
		[endDate, setEndDate] = useState(),
		[cohortIds, setCohortIds] = useState(''),
		[errorMessage, setErrorMessage] = useState({}),
		[evaluationView, setEvaluationView] = useState(false),
		[syncMode, setsyncMode] = useState(false),
		[selectValues] = useState([
			{
				value: 'Invite using cohort IDs'
			},
			{
				value: 'Invite by uploading a CSV'
			}
		]),
		[file, setFile] = useState(undefined),
		[selectedInvitationMethod, setSelectedInvitationMethod] = useState(
			selectValues[0].value
		),
		handleOnChange = (e) => {
			setEvaluationView(e.target.checked);
		},
		syncOnChange = (e) => {
			setsyncMode(e.target.checked);
		},
		isValidForm = () => {
			let errors = {};
			const cohortIdsRegex = /^([0-9]+)(,[0-9]+)*$/g;
			if (selectedInvitationMethod === selectValues[1].value) {
				if (!file) {
					errors.cohortIDInviteFile = 'Invitation File is required!';
				}
			} else {
				if (!cohortIdsRegex.test(cohortIds)) {
					errors.cohortIds = 'Invalid Comma Separated Cohort ids!';
				}
			}
			if (!(startDate && endDate)) {
				errors.datePickerField = 'Start and End date are required!';
			}
			setErrorMessage(errors);
			return Object.keys(errors)[0] ? false : true;
		},
		inviteStudents = async (id) => {
			if (!isValidForm()) return;
			setLoader({ isLoading: true });
			try {
				if (selectedInvitationMethod === selectValues[0].value) {
					await inviteToTest({
						test: id,
						start_time: startDate,
						end_time: endDate,
						cohort_ids: cohortIds.split(','),
						email_ids: [],
						evaluation_view: evaluationView,
						sync_mode: syncMode
					});
					showSnackBar({
						message: 'Invite Successfull!',
						type: 'success'
					});
				} else {
					const data = new FormData();
					data.append('test', id);
					data.append('start_time', startDate.toJSON());
					data.append('end_time', endDate.toJSON());
					data.append('email_list', file);
					data.append('evaluation_view', evaluationView);
					data.append('sync_mode', syncMode);
					await inviteToTest(data);
					showSnackBar({
						message: 'Invite Successfull!',
						type: 'success'
					});
				}
			} catch (err) {
				showSnackBar({
					message: 'Invite Failed!',
					type: 'danger'
				});
			}
			setLoader({ isLoading: false });
			closeModal();
		},
		onStartDateChange = (date) => {
			setStartDate(date);
		},
		onEndDateChange = (date) => {
			setEndDate(date);
		},
		handleCohortIdsChange = ({ value }) => {
			setCohortIds(value);
		},
		handleOnValueChange = (e) => {
			setSelectedInvitationMethod(e.target.value);
			if (e.target.value === selectValues[0].value) {
				setFile(null);
			}
		},
		handleFileUpload = (event) => {
			setFile(event.event.target.files[0]);
		};

	return (
		<Modal
			HeaderComponent={InviteModalHeader}
			onClose={closeModal}
			isActive={isActive}
			title="Invite Users to Test"
		>
			<form
				onSubmit={(e) => {
					e.preventDefault();
				}}
				className={styles.formInvite}
			>
				<div className={styles.datePickerField}>
					<DateRangePicker
						fromDate={startDate}
						toDate={endDate}
						onStartDateChange={onStartDateChange}
						onEndDateChange={onEndDateChange}
						maxStartDate={new Date()}
						time
					/>
					<ConditionalRender rIf={errorMessage.datePickerField}>
						<span className={styles.dateError}>
							{errorMessage.datePickerField}
						</span>
					</ConditionalRender>
				</div>
				<div className={styles.selectBox}>
					<label className={styles.selectLabel} htmlFor="selectBox">
						Choose how you want to invite
					</label>
					<select
						id="selectBox"
						onChange={handleOnValueChange}
						value={selectedInvitationMethod}
					>
						{selectValues.map(({ value }) => (
							<option key={value} value={value}>
								{value}
							</option>
						))}
					</select>
				</div>
				<ConditionalRender
					rIf={selectedInvitationMethod === selectValues[0].value}
				>
					<Input
						type="text"
						name="cohortIdList"
						label="Cohort Ids"
						value={cohortIds}
						onChange={handleCohortIdsChange}
						error={errorMessage.cohortIds}
					/>
				</ConditionalRender>
				<ConditionalRender
					rIf={selectedInvitationMethod === selectValues[1].value}
				>
					<Input
						type="file"
						name="inviteFile"
						onChange={handleFileUpload}
						label="Select Invite File"
						error={errorMessage.cohortIDInviteFile}
						accept=".csv"
					/>
					<div className={styles.downloadSampleFile}>
						<a href="https://cdn.upgrad.com/test-platform/sample-email-list.csv">
							Download Sample File
						</a>
					</div>
				</ConditionalRender>
				<div className={styles.evaluationView}>
					<input
						type="checkbox"
						onChange={syncOnChange}
						value={syncMode}
						className={styles.checkbox}
					/>
					<label>
						Enable Sync Mode (publishes results immediately)
					</label>
				</div>
				<div className={styles.evaluationView}>
					<input
						type="checkbox"
						onChange={handleOnChange}
						value={evaluationView}
						className={styles.checkbox}
					/>
					<label>Evaluation View</label>
				</div>
				<Button type="submit" onClick={() => inviteStudents(id)}>
					Invite
				</Button>
			</form>
		</Modal>
	);
}
TestInviteModal.propTypes = {
	id: PropTypes.any,
	closeModal: PropTypes.func,
	setLoader: PropTypes.func,
	showSnackBar: PropTypes.func,
	isActive: PropTypes.bool
};

function mapDispatchToProps(dispatch) {
	return {
		setLoader: bindActionCreators(setLoader, dispatch),
		showSnackBar: bindActionCreators(showSnackBar, dispatch)
	};
}

export default connect(null, mapDispatchToProps)(TestInviteModal);
