import React from "react";
import Icon from "./IconBase.js";

const QuestionBankIcon = props => (
	<Icon
		width="24"
		height="24"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M17.88 16.73a.7.7 0 0 1-.7.7h-2.83a5.92 5.92 0 0 0-3.43 1.08l-.52.36a.68.68 0 0 1-.8 0l-.52-.36a5.94 5.94 0 0 0-3.43-1.08H2.82a.7.7 0 0 1 0-1.4h2.83a7.37 7.37 0 0 1 4.23 1.33l.12.09.11-.08A7.33 7.33 0 0 1 14.35 16h2.83a.7.7 0 0 1 .7.73zM19 1.7v12.14a.7.7 0 0 1-.7.7h-4.51a6.07 6.07 0 0 0-3.4 1H10.24a.6.6 0 0 1-.48 0H9.61a6.07 6.07 0 0 0-3.4-1H1.7a.7.7 0 0 1-.7-.7V1.7a.7.7 0 0 1 .7-.7h4.51A7.5 7.5 0 0 1 10 2a7.5 7.5 0 0 1 3.79-1h4.51a.7.7 0 0 1 .7.7zM6.21 13.14a7.47 7.47 0 0 1 3.09.67V3.25a6.11 6.11 0 0 0-3.09-.85H2.4v10.74zM17.6 2.4h-3.81a6.11 6.11 0 0 0-3.09.85v10.56a7.47 7.47 0 0 1 3.09-.67h3.81z"
			fill="black"
		></path>
	</Icon>
);

export default QuestionBankIcon;
