import React from 'react';
import Icon from './IconBase.js';

const ChevronRight = (props) => (
	<Icon width="12" height="24" viewBox="0 0 8 5" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1.61258 0.779565C1.36454 0.54703 0.978574 0.54703 0.730536 0.779565C0.458799 1.03432 0.458799 1.46566 0.730536 1.72041L3.55896 4.37206C3.58997 4.40113 3.62313 4.42656 3.6579 4.44836C3.90134 4.60097 4.22398 4.57553 4.44101 4.37206L7.26944 1.72041C7.54117 1.46566 7.54117 1.03432 7.26944 0.779565C7.0214 0.54703 6.63543 0.54703 6.38739 0.779565L3.99999 3.01776L1.61258 0.779565Z"
			fill="white"
		/>
	</Icon>
);

export default ChevronRight;
