import React, { useState } from 'react';
import ListingTable from 'shared/ListingTable';
import { fetchTestList } from 'apis/testServiceApis';
import cx from 'classnames';
import styles from './index.scss';
import TestActions from './TestActions';
import Button from 'widgets/Button';
import CreateTestModal from './CreateTestModal';
import { DateTime } from 'luxon';
import ReactTooltip from 'react-tooltip';

function TestListing() {
	const [testList, setTestList] = useState([]);
	const [testCount, setTestCount] = useState(0);
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [currPage, setCurrPage] = useState(0);

	const fetchData = ({ page }) => {
		return fetchTestList({ page: page + 1 }).then(({ data }) => {
			setTestList(data.results);
			setTestCount(data.count);
			setCurrPage(page);
		});
	};
	const openCreateTestModal = () => {
		setIsOpenModal(true);
	};
	const closeCreateTestModal = () => {
		setIsOpenModal(false);
	};

	return (
		<div className={styles.container}>
			<div className={cx(styles.createTestContainer, 'ph-16')}>
				<h3 className={styles.HeaderTitle}>Tests Listing</h3>
				<Button
					className={cx(styles.createTestBtn)}
					onClick={openCreateTestModal}
				>
					Create Test
				</Button>
			</div>
			<div className={cx(styles.listingTableContainer, 'p-40')}>
				<ListingTable
					columns={[
						{ Header: 'Id', accessor: 'id', width: 80 },
						{
							id: 'name',
							Header: 'Test Name',
							width: 300,
							accessor: 'name',
							// eslint-disable-next-line react/display-name
							Cell: (row) => {
								return (
								<>
									<ReactTooltip />
									<p data-tip={row.row.name}>{row.row.name}</p>
								</>

							)}


						},
						{
							Header: 'Duration (in Mins)',
							accessor: 'duration',
							width: 150
						},
						{
							id: 'updatedOn',
							Header: 'Last Updated On',
							width: 150,
							accessor: (row) =>
								DateTime.fromISO(row.modifiedAt).toISODate()
						},
						{
							Header: 'Actions',
							accessor: 'id',
							// eslint-disable-next-line react/display-name
							Cell: (props) => (
								<TestActions
									updateTestList={fetchData}
									{...props}
								/>
							),
							getProps: () => {
								return {
									style: {
										padding: '13px 5px'
									}
								};
							}
						}
					]}
					page={currPage}
					className={styles.testTable}
					data={testList}
					pageSize={10}
					fetchData={fetchData}
					totalRecordCount={testCount}
				/>
			</div>
			{isOpenModal && (
				<CreateTestModal
					closeCreateTestModal={closeCreateTestModal}
					updateTestList={fetchData}
					isActive={isOpenModal}
				/>
			)}
		</div>
	);
}

export default TestListing;
