import axios from 'axios';
import constants from 'constants';
import { store } from '../../store';
import { getCookie } from '../../components/utils/cookies';
import { logoutUser as logoutUserAction } from 'actions/user';
import rollbar from '../../components/utils/rollbar';
import { push } from 'connected-react-router';

/*
    TODO Write a network layer before axios,
    Handle cancellation of network call or ignoring stale response
    Camelize all api keys at deep level
*/

const authApiInstance = axios.create({
	baseURL: constants.authApiServerUrl,
	timeout: 0
});

authApiInstance.interceptors.request.use((config) => {
	let userInfo = store.getState().userInfo;
	config.headers[constants.authTokenBackendKey] =
		getCookie('upgrad-auth-token.' + constants.env) || userInfo.authToken;
	config.headers['sessionId'] =
		getCookie('upgrad-sessionId.' + constants.env) || userInfo.sessionId;
	return config;
});

authApiInstance.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error.response.status === 401) {
			const { data } = error.response;
			const message =
				data.errorList[0].errorMessage ||
				'Something went wrong for which you need to contact upGrad support. Thank you for your patience.';

			store.dispatch(logoutUserAction(message));
		} else if (error.response?.status > 401) {
			if (error.response.status === 401) {
				const { data } = error.response;
				const message =
					data.errorList[0].errorMessage ||
					'Something went wrong for which you need to contact upGrad support. Thank you for your patience.';

				store.dispatch(logoutUserAction(message));
			} else {
				if (constants.env === 'local') {
					// eslint-disable-next-line no-console
					console.log(error.response);
				} else {
					rollbar.error(
						`Backend Error [${error.response.status}] with test session API: `,
						error
					);
					store.dispatch(push(`/error/${error.response.status}`));
				}
			}
		}

		return Promise.reject(error);
	}
);


export default authApiInstance;
