import actionTypes from 'actionTypes';

export default function commonReducer(
	state = { isLoading: false, layoutClassName: '', offline: false },
	action
) {
	const { type, payload } = action;
	switch (type) {
		case actionTypes.SET_LOADING:
			return {
				...state,
				isLoading: payload.isLoading
			};
		case 'SET_OFFLINE':
			return {
				...state,
				offline: payload.status
			};
	}
	return state;
}
