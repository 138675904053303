import searchActionTypes from "actionTypes/search.js";

export default function searchReducer(
	state = {
		searchResults: [],
		searchParams: {
			difficulty: [],
			tags: [],
			qtype: [],
			status: [],
			query: "",
			fromDate: null,
			toDate: null,
			created_by: []
		}
	},
	action
) {
	const { type, payload } = action;
	switch (type) {
		case searchActionTypes.SHOW_RESULTS:
			return {
				...state,
				searchResults: payload.results,
				nextQuestionListUrl: payload.nextQuestionListUrl,
				isFetching: false,
				count: payload.count
			};
		case searchActionTypes.FETCHING_RESULTS:
			return {
				...state,
				...payload
			};
		case searchActionTypes.SET_SEARCH_KEYWORD:
			return {
				...state,
				searchParams: { ...state.searchParams, query: payload.query }
			};
		case searchActionTypes.SET_SEARCH_TAGS:
			return {
				...state,
				searchParams: {
					...state.searchParams,
					tags: [...state.searchParams.tags, payload.tag]
				}
			};
		case searchActionTypes.REMOVE_SEARCH_TAG:
			return {
				...state,
				searchParams: {
					...state.searchParams,
					tags: state.searchParams.tags.filter(
						tag => tag != payload.tag
					)
				}
			};
		case searchActionTypes.SET_SEARCH_STATUS:
			return {
				...state,
				searchParams: {
					...state.searchParams,
					status: [...state.searchParams.status, payload.status]
				}
			};
		case searchActionTypes.REMOVE_SEARCH_STATUS:
			return {
				...state,
				searchParams: {
					...state.searchParams,
					status: state.searchParams.status.filter(
						status => status != payload.status
					)
				}
			};
		case searchActionTypes.SET_SEARCH_DIFFICULTY:
			return {
				...state,
				searchParams: {
					...state.searchParams,
					difficulty: [
						...state.searchParams.difficulty,
						payload.difficulty
					]
				}
			};
		case searchActionTypes.REMOVE_SEARCH_DIFFICULTY:
			return {
				...state,
				searchParams: {
					...state.searchParams,
					difficulty: state.searchParams.difficulty.filter(
						difficulty => difficulty != payload.difficulty
					)
				}
			};
		case searchActionTypes.SET_SEARCH_TYPE:
			return {
				...state,
				searchParams: {
					...state.searchParams,
					qtype: [...state.searchParams.qtype, payload.qtype]
				}
			};
		case searchActionTypes.REMOVE_SEARCH_TYPE:
			return {
				...state,
				searchParams: {
					...state.searchParams,
					qtype: state.searchParams.qtype.filter(
						qtype => qtype != payload.qtype
					)
				}
			};
		case searchActionTypes.SET_FROM_DATE:
			return {
				...state,
				searchParams: {
					...state.searchParams,
					start_date: payload.fromDate
				}
			};
		case searchActionTypes.SET_TO_DATE:
			return {
				...state,
				searchParams: {
					...state.searchParams,
					end_date: payload.toDate
				}
			};
		case searchActionTypes.CLEAR_SEARCH_FILTERS:
			return {
				...state,
				searchParams: {
					...state.searchParams,
					qtype: [],
					difficulty: [],
					status: [],
					start_date: null,
					end_date: null,
					created_by: []
				}
			};
		case searchActionTypes.CLEAR_SEARCH_TAGS:
			return {
				...state,
				searchParams: {
					...state.searchParams,
					tags: []
				}
			};
		case searchActionTypes.SET_CREATED_BY_USERS:
			return {
				...state,
				searchParams: {
					...state.searchParams,
					created_by: payload.created_by
				}
			};
	}
	return state;
}
