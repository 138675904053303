import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as userActions from "actions/user";
import cx from "classnames";
import styles from "./MenuPreview.scss";
import { shorten } from "utils/lengthLimiter";

class MenuPreview extends Component {
	static propTypes = {
		onClose: PropTypes.func,
		userActions: PropTypes.object.isRequired,
		userInfo: PropTypes.object.isRequired
	};

	componentDidMount() {
		document.addEventListener("click", this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener("click", this.handleClickOutside);
	}

	handleUserLogout = () => {
		const { userActions } = this.props;
		userActions.logoutUser();
	};

	handleClickOutside = event => {
		if (event.target !== event.currentTarget) {
			this.props.onClose();
		}
	};

	render() {
		const { userInfo } = this.props;

		return (
			<div tabIndex="2" className={styles.profileMenuBox}>
				<div className={styles.content}>
					<div className={styles.menu}>
						<div className={styles.info}>
							<div className={styles.email}>
								{shorten(userInfo.email, 26)}
							</div>
							<div className={styles.menuItemUser}>
								{userInfo.role}
							</div>
						</div>

						{/* <div className={styles.separator} />
						<Link
							to={routesPath.homePage}
							className={styles.itemWrapper}
						>
							<div className={styles.item}>
								<span>Home</span>
							</div>
						</Link>
						<Link
							to={routesPath.pageListerPage}
							className={styles.itemWrapper}
						>
							<div className={styles.item}>
								<span>Pages</span>
							</div>
						</Link> */}
						<div className={styles.separator} />
						<div
							className={styles.itemWrapper}
							onClick={this.handleUserLogout}
						>
							<div className={cx(styles.item, styles.danger)}>
								<span>Log out</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		userInfo: state.userInfo
	};
}

function mapDispatchToProps(dispatch) {
	return {
		userActions: bindActionCreators(userActions, dispatch)
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MenuPreview);
