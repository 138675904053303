import React from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { hot } from 'react-hot-loader';
import Router from './Router';

const Root = ({ store, history }) => {
	return (
		<Provider store={store}>
			<Router history={history} />
		</Provider>
	);
};

Root.propTypes = {
	store: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired
};

export default hot(module)(Root);
