import snackbarActionTypes from "actionTypes/snackbar";
export const showSnackBar = function(payload) {
	return {
		type: snackbarActionTypes.SHOW_SNACKBAR,
		payload
	};
};

export const hideSnackBar = function() {
	return {
		type: snackbarActionTypes.HIDE_SNACKBAR
	};
};
