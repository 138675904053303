import actionTypes from 'actionTypes';
import * as apis from 'apis';
import { allowedUserChecker, randomString } from 'utils';

import constants from 'constants';
import {
	setCookie,
	getCookie,
	deleteCookie
} from './../components/utils/cookies';
import { push } from 'connected-react-router';

export function loginUser({ email, password }) {
	return (dispatch) => {
		let ssid = randomString(32);
		setCookie({ [`upgrad-sessionId.${constants.env}`]: ssid });
		return apis
			.loginUser({ email, password })
			.then((response) => {
				const data = response.data,
					allowedUser = allowedUserChecker(
						constants.allowedUserRole,
						data.roles
					);
				if (data.id && allowedUser) {
					let cookieData = {},
						authCookieKey = `upgrad-auth-token.${constants.env}`;
					cookieData[authCookieKey] = response.headers['auth-token'];
					setCookie(cookieData);
					dispatch(getUserDetails())
				} else {
					throw 'Permission Denied';
				}
			})
			.catch(() => {
				deleteCookie([`upgrad-sessionId.${constants.env}`]);
			});
	};
}

export function signUpUser(userDetails) {
	return(dispatch) => {
		let ssid = randomString(32);
		setCookie({ [`upgrad-sessionId.${constants.env}`]: ssid });
		return apis
			.signUpUser(userDetails)
			.then((response) => {
				const data = response.data.user,
				allowedUser = allowedUserChecker(
					constants.allowedUserRole,
					data.rolesStringSet
				);
				if (data.id && allowedUser) {
					let cookieData = {},
						authCookieKey = `upgrad-auth-token.${constants.env}`;
					cookieData[authCookieKey] = response.headers['auth-token'];
					setCookie(cookieData);
					dispatch({
						type: actionTypes.SIGNUP_USER,
						payload: {
							token: response.headers['auth-token'],
							email: data.email,
							roles: data.roles,
							firstName: data.firstname,
							lastName: data.lastname,
							id: data.id,
							doselect: data.doSelectProperties
						}
					});
				} else {
					throw 'Permission Denied';
				}
			})
			.catch(() => {
				deleteCookie([`upgrad-sessionId.${constants.env}`]);
			})
	}
}

export function getUserDetails() {
	return (dispatch) => {
		return apis
			.getUserDetails()
			.then((response) => {
				let data = response.data,
					allowedUser = allowedUserChecker(
						constants.allowedUserRole,
						data.rolesStringSet
					);
				if (allowedUser) {
					dispatch({
						type: actionTypes.GET_USER_DETAILS,
						payload: {
							authToken: getCookie(
								`upgrad-auth-token.${constants.env}`
							),
							email: data.email,
							roles: data.roles,
							firstName: data.firstname,
							lastName: data.lastname,
							id: data.id,
							doselect: data.doSelectProperties
						}
					});
				} else {
					dispatch(push(`/error/401`));
				}
			})
			.catch(() => {
				dispatch(push(`/error/500`));
			});
	};
}

export function logoutUser(errorMessage = '') {
	return (dispatch) => {
		if (getCookie('upgrad-auth-token.' + constants.env)) {
			return apis
				.logoutUser()
				.then((response) => {
					const data = response.data;
					if (data.success) {
						deleteCookie([
							`upgrad-auth-token.${constants.env}`,
							`upgrad-sessionId.${constants.env}`
						]);
						dispatch({
							type: actionTypes.LOGOUT_USER,
							payload: {
								errorMessage
							}
						});
					}
				})
				.catch();
		} else {
			deleteCookie([
				`upgrad-auth-token.${constants.env}`,
				`upgrad-sessionId.${constants.env}`
			]);
			dispatch({
				type: actionTypes.LOGOUT_USER,
				payload: {
					errorMessage
				}
			});
		}
	};
}

export function resetUser() {
	return (dispatch) => {
		dispatch({
			type: actionTypes.RESET_USER
		});
	};
}
