import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table";
import "react-table/react-table.css";
import cx from "classnames";

function ListingTable({
	columns,
	data,
	fetchData,
	pageSize,
	className,
	totalRecordCount,
	page,
	// getTrProps
}) {
	const [loading, setLoading] = useState(false);
	const fetchTableData = async state => {
		if (loading) return;
		try {
			setLoading(true);
			await fetchData(state);
		} catch (e) {
			// console.log(e);
		}
		setLoading(false);
	};
	return (
		<ReactTable
			columns={columns}
			sortable={false}
			filterable={false}
			page={page}
			manual // Forces table not to paginate or sort automatically, so we can handle it server-side
			data={data}
			pages={Math.ceil(totalRecordCount / pageSize)}
			loading={loading} // Display the loading overlay when we need it
			onFetchData={fetchTableData} // Request new data when things change
			defaultPageSize={pageSize}
			minRows={0}
			showPageSizeOptions={false}
			resizable={false}
			className={cx("-striped", "-highlight", className)}
			getTrProps={() => { // Add (state, rowInfo) to be able to use onClick function on rows
				return {
					// onClick: () => getTrProps(rowInfo),
					style: {
						height: "100%"
					}
				};
			}}
		/>
	);
}

ListingTable.defaultProps = {
	data: [],
	pageSize: 10
};

ListingTable.propTypes = {
	columns: PropTypes.array.isRequired,
	data: PropTypes.array,
	fetchData: PropTypes.func.isRequired,
	pageSize: PropTypes.number,
	className: PropTypes.string,
	totalRecordCount: PropTypes.number,
	page: PropTypes.number,
	getTrProps: PropTypes.func
};

export default ListingTable;
