import React from 'react';
// import PropTypes from "prop-types";
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import routePaths from './routePaths';
import TestListing from './components/TestListing';
import InvitationListing from './components/InvitationListing';
import styles from './index.scss';

function TestListPage() {
	let { url } = useRouteMatch();

	return (
		<div className={styles.testListContainer}>
			<Switch>
				<Route path={url} exact>
					<TestListing />
				</Route>
				<Route path={`${url}${routePaths.invitations}`}>
					<InvitationListing />
				</Route>
				<Route path="*">
					<Redirect to="/404" />
				</Route>
			</Switch>
		</div>
	);
}

export default TestListPage;
