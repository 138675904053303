import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from '../reducers';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

history.listen(() => {
	window.scrollTo(0, 0);
});

function configureStoreProd(initialState) {
	const reactRouterMiddleware = routerMiddleware(history);
	const middlewares = [
		// Add other middleware on this line...

		// thunk middleware can also accept an extra argument to be passed to each thunk action
		// https://github.com/reduxjs/redux-thunk#injecting-a-custom-argument
		thunk,
		reactRouterMiddleware
	];

	let store = createStore(
		createRootReducer(history),
		initialState,
		compose(applyMiddleware(...middlewares))
	);
	return { store };
}

function configureStoreDev(initialState) {
	const reactRouterMiddleware = routerMiddleware(history);
	const middlewares = [
		// Add other middleware on this line...

		// Redux middleware that spits an error on you when you try to mutate your state either inside a dispatch or between dispatches.
		// reduxImmutableStateInvariant(),

		// thunk middleware can also accept an extra argument to be passed to each thunk action
		// https://github.com/reduxjs/redux-thunk#injecting-a-custom-argument
		thunk,
		reactRouterMiddleware
	];

	const composeEnhancers =
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
	const store = createStore(
		createRootReducer(history),
		initialState,
		composeEnhancers(applyMiddleware(...middlewares))
	);

	if (module.hot) {
		// Enable Webpack hot module replacement for reducers
		module.hot.accept('../reducers', () => {
			const nextReducer = require('../reducers').default; // eslint-disable-line global-require
			store.replaceReducer(nextReducer);
		});
	}
	return { store };
}

const configureStore =
	process.env.NODE_ENV === 'production'
		? configureStoreProd
		: configureStoreDev;

export default configureStore;
