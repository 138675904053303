import testAttemptActionTypes from 'actionTypes/testAttempt';
import produce from 'immer';

export default function testAttempt(
	state = {
		questionList: [],
		activeQuestionId: 0,
		testSessionData: {},
		testData: {},
		sectionList: [],
		score: {},
		loader: false,
		solutionMode: false,
		fullScreen: false
	},
	action
) {
	switch (action.type) {
		case testAttemptActionTypes.SET_ACTIVE_QUESTION:
			return {
				...state,
				activeQuestionId: action.payload.activeQuestionId
			};
		case testAttemptActionTypes.SET_QUESTION_LIST:
			return {
				...state,
				sectionList: action.payload.sectionList,
				questionList: action.payload.sectionList.reduce(
					(acc, section, index) => {
						return acc.concat(
							section.questions.map((question, i) => {
								question.section = {
									sectionId: section.id,
									sectionName: section.name,
									index: index
								};
								question.userResponse =
									question.userResponse || {};
								question.indexInSection = i;
								return question;
							})
						);
					},
					[]
				)
			};
		case testAttemptActionTypes.INCREMENT_ACTIVE_QUESTION_ID:
			return {
				...state,
				activeQuestionId:
					(state.activeQuestionId + 1) % state.questionList.length
			};
		case testAttemptActionTypes.DECREMENT_ACTIVE_QUESTION_ID:
			return {
				...state,
				activeQuestionId: state.activeQuestionId
					? state.activeQuestionId - 1
					: state.questionList.length - 1
			};
		case testAttemptActionTypes.SET_ANSWER: {
			return produce(state, (draft) => {
				let {
					questionList,
					sectionList,
					activeQuestionId,
					testData
				} = draft,
					selectedQuestion = questionList[activeQuestionId],
					sIndex = selectedQuestion.section.index;
				const { payload } = action;
				if (selectedQuestion.qtype === 'checkbox') {
					selectedQuestion.options = selectedQuestion.options.map(
						(o) => {
							if (payload.indexOf(o.id) >= 0) {
								o.selectedByUser = true;
							} else {
								o.selectedByUser = false;
							}
							return o;
						}
					);
				} else if (selectedQuestion.qtype === 'radio') {
					selectedQuestion.options = selectedQuestion.options.map(
						(o) => {
							if (o.id === payload) {
								o.selectedByUser = true;
							} else {
								o.selectedByUser = false;
							}
							return o;
						}
					);
				} else {
					selectedQuestion.options[0].userAnswer = payload;
				}
				if (!questionList[activeQuestionId].isAttempted) {
					testData.stats.answered += 1;
					testData.stats.unanswered -= 1;
					selectedQuestion.isAttempted = true;
				}
				sectionList[sIndex].questions.forEach((q) => {
					if (q.question === selectedQuestion.question) {
						q.isAttempted = selectedQuestion.isAttempted;
						q.options = selectedQuestion.options;
					}
				});
			});
		}
		case testAttemptActionTypes.SET_IS_MARKED_FOR_REVIEW: {
			return produce(state, (draft) => {
				const { activeQuestionId } = draft;
				const activeQuestion = draft.questionList[activeQuestionId],
					{
						section: { index },
						indexInSection
					} = activeQuestion;
				draft.sectionList[index].questions[
					indexInSection
				].markedForReview = action.payload.markedForReview;
				activeQuestion.markedForReview = action.payload.markedForReview;
				if (action.payload.markedForReview) {
					draft.testData.stats.markedForReview += 1;
				} else {
					draft.testData.stats.markedForReview -= 1;
				}
			});
		}

		case testAttemptActionTypes.SET_TEST_SESSION_DATA:
			return {
				...state,
				testSessionData: action.data
			};

		case testAttemptActionTypes.SET_TEST_SCORE:
			return {
				...state,
				testSessionData: action.data.session,
				testData: action.data.testData,
				sectionList: action.data.sections,
				solutionMode: true,
				questionList: action.data.sections
					? action.data.sections.reduce((acc, section, index) => {
						return acc.concat(
							section.questions.map((question, i) => {
								question.section = {
									sectionId: section.id,
									sectionName: section.name,
									index: index
								};
								question.userResponse =
									question.userResponse || {};
								question.indexInSection = i;
								return question;
							})
						);
					}, [])
					: []
			};

		case testAttemptActionTypes.SET_TEST_DATA:
			return {
				...state,
				testData: action.data.testData,
				sectionList: action.data.sectionList,
				questionList: action.data.sectionList.reduce(
					(acc, section, index) => {
						return acc.concat(
							section.questions.map((question, i) => {
								question.section = {
									sectionId: section.id,
									sectionName: section.name,
									index: index
								};
								question.userResponse =
									question.userResponse || {};
								question.indexInSection = i;
								return question;
							})
						);
					},
					[]
				)
			};
		case testAttemptActionTypes.CLEAR_STORE:
			return {
				questionList: [],
				activeQuestionId: 0,
				testSessionData: {},
				testData: {},
				sectionList: [],
				score: {},
				loader: false,
				solutionMode: false,
				fullScreen: false
			};
		case testAttemptActionTypes.SET_SUBTLE_LOADER:
			return {
				...state,
				loader: action.isLoading
			};
		case testAttemptActionTypes.START_TEST:
			return {
				...state,
				testSessionData: {
					...state.testSessionData,
					sessionStatus: 'started',
					endTime: action.endTime
				}
			};
		case testAttemptActionTypes.END_TEST:
			return {
				...state,
				testSessionData: {
					...state.testSessionData,
					sessionStatus: 'submitted'
				}
			};
		case testAttemptActionTypes.SET_FULL_SCREEN_VAL:
			return {
				...state,
				fullScreen: action.val
			};
		case testAttemptActionTypes.DS_COUNT_SET: {
			let { questionList, sectionList } = state,
				{ slug } = action;
			return produce(state, (draft) => {
				questionList.forEach((q, i) => {
					if (
						q.options[0]?.code_ds_ref === slug &&
						!draft.questionList[i].isAttempted
					) {
						draft.testData.stats.answered += 1;
						draft.testData.stats.unanswered -= 1;
						draft.questionList[i].isAttempted = true;
						sectionList[q.section.index].questions.forEach(
							(sq, si) => {
								if (sq.options[0]?.code_ds_ref === slug) {
									draft.sectionList[
										q.section.index
									].questions[si].isAttempted = true;
								}
							}
						);
					}
				});
			});
		}
	}
	return state;
}
