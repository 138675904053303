import React from "react";
import PropTypes from "prop-types";
import {
	publishTest,
	closeTest,
	archiveTest,
	unpublishTest
} from "apis/testServiceApis";
import { bindActionCreators } from "redux";
import { showSnackBar } from "actions/snackBar";
import { setLoader } from "actions/common";
import { connect } from "react-redux";
import TestStates from 'modules/TestListPage/constants';
import styles from "./index.scss";

function TestStateDropdown({
	id,
	status,
	showSnackBar,
	setLoader,
	updateTestList
}) {
	const options = [
		{ label: "Archived", value: TestStates.Archived },
		{ label: "Draft", value: TestStates.Draft },
		{ label: "Published", value: TestStates.Published },
		{ label: "Closed", value: TestStates.Closed }
	];
	const isActionValid = (status, action) => {
		let isShown = false;
		switch (status) {
			case TestStates.Published:
				isShown =
					action == TestStates.Closed || action == TestStates.Draft;
				break;
			case TestStates.Closed:
				isShown =
					action == TestStates.Archived ||
					action == TestStates.Published;
				break;
			case TestStates.Archived:
				isShown = false;
				break;
			default:
				isShown = action == TestStates.Published;
		}
		return isShown || action == status;
	};
	const onStateSelect = e => {
		switch (e.target.value) {
			case TestStates.Published:
				publishTestById(id);
				break;
			case TestStates.Draft:
				unpublishTestById(id);
				break;
			case TestStates.Closed:
				closeTestById(id);
				break;
			case TestStates.Archived:
				archiveTestById(id);
		}
	};
	const closeTestById = async id => {
		setLoader({ isLoading: true });
		try {
			await closeTest(id);
			await updateTestList({ page: 0 });
			showSnackBar({
				message: "Test Closed Successfull!",
				type: "success"
			});
		} catch (e) {
			showSnackBar({
				message: "Test Close Failed",
				type: "danger"
			});
		}
		setLoader({ isLoading: false });
	};
	const archiveTestById = async id => {
		setLoader({ isLoading: true });
		try {
			await archiveTest(id);
			await updateTestList({ page: 0 });
			showSnackBar({
				message: "Test Archive Successfull!",
				type: "success"
			});
		} catch (e) {
			showSnackBar({
				message: "Test Archive Failed!",
				type: "danger"
			});
		}
		setLoader({ isLoading: false });
	};
	const unpublishTestById = async id => {
		setLoader({ isLoading: true });
		try {
			await unpublishTest(id);
			await updateTestList({ page: 0 });
			showSnackBar({
				message: "Test Unpublish Successfull!",
				type: "success"
			});
		} catch (e) {
			showSnackBar({
				message: "Test Unpublish Failed!",
				type: "danger"
			});
		}
		setLoader({ isLoading: false });
	};
	const publishTestById = async id => {
		setLoader({ isLoading: true });
		try {
			await publishTest(id);
			await updateTestList({ page: 0 });
			showSnackBar({
				message: "Test Publish Successfull!",
				type: "success"
			});
		} catch (e) {
			showSnackBar({
				message: "Test Publish Failed!",
				type: "danger"
			});
		}
		setLoader({ isLoading: false });
	};
	return (
		<div className={styles.dropdownBox}>
			<select
				onChange={onStateSelect}
				className={styles.dropdown}
				value={status}
			>
				{options.map(({ label, value }, i) => {
					if (isActionValid(status, value)) {
						return (
							<option value={value} key={i}>
								{label}
							</option>
						);
					}
				})}
			</select>
		</div>
	);
}

TestStateDropdown.propTypes = {
	id: PropTypes.any,
	status: PropTypes.string,
	setLoader: PropTypes.func,
	showSnackBar: PropTypes.func,
	updateTestList: PropTypes.func
};

function mapDispatchToProps(dispatch) {
	return {
		setLoader: bindActionCreators(setLoader, dispatch),
		showSnackBar: bindActionCreators(showSnackBar, dispatch)
	};
}

export default connect(
	null,
	mapDispatchToProps
)(TestStateDropdown);
