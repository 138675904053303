import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.scss';
import TestStateDropdown from './TestStateDropdown';
import ActionDropDown from './ActionDropDown';
import TestStates from 'modules/TestListPage/constants';
import ConditionalRender from 'shared/ConditionalRender';
import TestInvite from './TestInvite';
import { cloneTest } from 'apis/testServiceApis';
import Button from 'widgets/Button';
import { bindActionCreators } from 'redux';
import { showSnackBar } from 'actions/snackBar';
import { setLoader } from 'actions/common';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const TestActions = ({ original, updateTestList, showSnackBar, setLoader }) => {
	const { id, status } = original;
	const cloneTestById = async () => {
		setLoader({ isLoading: true });
		try {
			await cloneTest(id);
			showSnackBar({
				message: 'Test Clone Successfull!',
				type: 'success'
			});
		} catch (e) {
			showSnackBar({
				message: 'Test Clone Failed!',
				type: 'danger'
			});
		}
		try {
			await updateTestList({ page: 0 });
		} catch (e) {
			showSnackBar({
				message:
					'Test List Update Failed! Please refresh the table to see the updated list.',
				type: 'danger'
			});
		}
		setLoader({ isLoading: false });
	};
	return (
		<div className={styles.actionContainer}>
			<TestStateDropdown
				status={status}
				id={id}
				updateTestList={updateTestList}
			/>
			<ConditionalRender rIf={status == TestStates.Published}>
				<TestInvite id={id} updateTestList={updateTestList} />
			</ConditionalRender>
			<ActionDropDown cloneTestById={cloneTestById} rowData={original}/>
			<ConditionalRender rIf={status !== TestStates.Draft}>
				<Link to={`/test/${id}/invitations`}>
					<Button variant="secondary">View Invitations</Button>
				</Link>
			</ConditionalRender>
		</div>
	);
};

TestActions.propTypes = {
	original: PropTypes.object,
	updateTestList: PropTypes.func,
	setLoader: PropTypes.func,
	showSnackBar: PropTypes.func
};

function mapDispatchToProps(dispatch) {
	return {
		setLoader: bindActionCreators(setLoader, dispatch),
		showSnackBar: bindActionCreators(showSnackBar, dispatch)
	};
}

export default connect(null, mapDispatchToProps)(TestActions);
