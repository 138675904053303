import mapKeys from 'utils/mapKeys';

export default mapKeys([
	"UPDATE_LAYOUT_PROPS",
	"LOGIN_USER",
	"SIGNUP_USER",
	"GET_USER_DETAILS",
	"SET_SESSION_ID",
	"LOGOUT_USER",
	"RESET_USER",
	"SET_ACTIVE_QUESTION_ID",
	"SET_LOADING"
]);
