import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.scss';
import cx from 'classnames';

const Modal = (props) => {
	const {
		isActive,
		title,
		children,
		footerNode,
		hideCloseButton,
		hideBackdrop,
		onClose,
		className,
		onMouseDown,
		onMouseUp,
		onTouchStart,
		onTouchEnd,
		containerClassName
	} = props;

	if (!isActive) {
		return null;
	}

	return (
		<div
			className={cx(className, styles.root)}
			onMouseDown={onMouseDown}
			onMouseUp={onMouseUp}
			onTouchStart={onTouchStart}
			onTouchEnd={onTouchEnd}
		>
			{!hideBackdrop ? (
				<div className={styles.backdrop} onClick={onClose} />
			) : null}
			<div className={styles.container}>
				<div className={cx(styles.main, containerClassName)}>
					<div
						className={cx(
							styles.close,
							`${hideCloseButton ? styles.hideButton : ''}`
						)}
						onClick={onClose}
					/>
					{title ? <h6 className={styles.title}>{title}</h6> : null}
					<div className={styles.content}>{children}</div>
					{footerNode && (
						<div className={styles.footer}>{footerNode}</div>
					)}
				</div>
			</div>
		</div>
	);
};

Modal.propTypes = {
	children: PropTypes.element,
	footerNode: PropTypes.element,
	isActive: PropTypes.bool.isRequired,
	title: PropTypes.string,
	onClose: PropTypes.func,
	hideCloseButton: PropTypes.bool,
	hideBackdrop: PropTypes.bool,
	className: PropTypes.string,
	onMouseDown: PropTypes.func,
	onMouseUp: PropTypes.func,
	onTouchStart: PropTypes.func,
	onTouchEnd: PropTypes.func,
	containerClassName: PropTypes.string
};

export default Modal;
