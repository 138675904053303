import React from "react";
import PropTypes from "prop-types";
import styles from "./index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames/bind";
const cx = classnames.bind(styles);

function SnackBar({ message, type, onClose, autoClose }) {
	if (autoClose) {
		setTimeout(() => {
			onClose();
		}, 5000);
	}
	return (
		<div className={styles.snackbarWrapper}>
			<div className={cx("snackbar", "ph-24", "pv-12", type)}>
				<span className={styles.message}>{message}</span>
				<span className={cx("closeCta", "ml-12")} onClick={onClose}>
					<FontAwesomeIcon icon={faTimes} />
				</span>
			</div>
		</div>
	);
}
SnackBar.defaultProps = {
	type: "",
	autoClose: true
};
SnackBar.propTypes = {
	message: PropTypes.string.isRequired,
	type: PropTypes.string,
	onClose: PropTypes.func,
	autoClose: PropTypes.bool
};

export default SnackBar;
