import React from 'react';
import cx from 'classnames';
import { NavLink } from 'react-router-dom';
import routesPath from './../../routePaths';
import styles from './index.scss';
import { useSelector } from 'react-redux';

const PrimaryNav = function () {
	const role = useSelector((s) => s.userInfo.roles).map((r) => r.role);

	return (
		<div className={styles.root}>
			<div className={cx(styles.navList)}>
				{(role.indexOf('ROLE_TEACHER') >= 0 ||
					role.indexOf('ROLE_EXTERNAL_SOURCER') >= 0 ||
					role.indexOf('ROLE_ADMIN') >= 0) && (
					<NavLink
						exact
						to={routesPath.questions.route}
						className={cx(styles.navItem)}
						activeClassName={styles.active}
					>
						Questions
					</NavLink>
				)}
				{(role.indexOf('ROLE_TEACHER') >= 0 ||
					role.indexOf('ROLE_ADMIN') >= 0) && (
					<NavLink
						exact
						to={routesPath.testListPage.route}
						className={styles.navItem}
						activeClassName={styles.active}
					>
						Tests
					</NavLink>
				)}
				{role.indexOf('ROLE_STUDENT') >= 0 && (
					<NavLink
						exact
						to={routesPath.invitation.route}
						className={styles.navItem}
						activeClassName={styles.active}
					>
						Invitations
					</NavLink>
				)}
			</div>
		</div>
	);
};

export default PrimaryNav;
