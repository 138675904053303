import React from "react";
import Icon from "./IconBase.js";

const CodingQuestionIcon = props => (
	<Icon
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#clip0)">
			<path
				d="M6.86392 17.7C6.5702 17.7004 6.28659 17.5928 6.06712 17.3976L-0.00488281 12L6.40432 6.30238C6.64321 6.09801 6.95277 5.99556 7.26642 6.01705C7.58007 6.03854 7.87276 6.18226 8.08154 6.4173C8.29033 6.65234 8.39854 6.95994 8.3829 7.27393C8.36726 7.58793 8.22903 7.88324 7.99792 8.09638L3.60712 12L7.66072 15.6024C7.84283 15.764 7.97149 15.9771 8.0296 16.2136C8.08771 16.45 8.07253 16.6985 7.98607 16.9261C7.89961 17.1537 7.74597 17.3496 7.54554 17.4878C7.34512 17.6261 7.10739 17.7001 6.86392 17.7V17.7ZM17.5979 17.6976L24.0071 12L17.9351 6.60238C17.6972 6.39105 17.3851 6.28289 17.0675 6.30168C16.7498 6.32047 16.4526 6.46468 16.2413 6.70258C16.03 6.94048 15.9218 7.25258 15.9406 7.57023C15.9594 7.88788 16.1036 8.18505 16.3415 8.39638L20.3951 12L16.0043 15.9024C15.7663 16.1137 15.6219 16.4109 15.603 16.7287C15.5841 17.0465 15.6922 17.3587 15.9035 17.5968C16.1148 17.8348 16.4121 17.9792 16.7298 17.9981C17.0476 18.017 17.3599 17.9089 17.5979 17.6976V17.6976ZM11.9843 19.398L14.3843 4.99798C14.415 4.8406 14.4138 4.67867 14.3809 4.52175C14.348 4.36483 14.2839 4.2161 14.1925 4.08436C14.1011 3.95261 13.9843 3.84051 13.8488 3.75468C13.7134 3.66886 13.5621 3.61104 13.404 3.58466C13.2458 3.55827 13.084 3.56385 12.928 3.60106C12.7721 3.63827 12.6251 3.70636 12.4959 3.8013C12.3667 3.89625 12.2578 4.01613 12.1757 4.15385C12.0936 4.29158 12.04 4.44436 12.0179 4.60318L9.61792 19.0032C9.58723 19.1606 9.58839 19.3225 9.62133 19.4794C9.65428 19.6363 9.71833 19.7851 9.80972 19.9168C9.9011 20.0485 10.018 20.1606 10.1534 20.2465C10.2888 20.3323 10.4401 20.3901 10.5983 20.4165C10.7564 20.4429 10.9183 20.4373 11.0742 20.4001C11.2302 20.3629 11.3771 20.2948 11.5063 20.1999C11.6355 20.1049 11.7444 19.985 11.8265 19.8473C11.9086 19.7096 11.9623 19.5568 11.9843 19.398V19.398Z"
				fill="black"
			/>
		</g>
		<defs>
			<clipPath id="clip0">
				<rect width="24" height="24" fill="white" />
			</clipPath>
		</defs>
	</Icon>
);

export default CodingQuestionIcon;
