import axios from 'axios';
import constants from 'constants';
import { store } from '../../store';
import { logoutUser } from 'actions/user';
import { getCookie } from './../../components/utils/cookies';
import { push } from 'connected-react-router';
import rollbar from '../../components/utils/rollbar';

const testServiceApiInstance = axios.create({
	baseURL: constants.testApiServerUrl,
	timeout: 0
});

testServiceApiInstance.interceptors.request.use((config) => {
	config.headers[constants.authTokenBackendKey] = getCookie(
		'upgrad-auth-token.' + constants.env
	);
	config.headers['sessionId'] = getCookie(
		'upgrad-sessionId.' + constants.env
	);
	return config;
});

testServiceApiInstance.interceptors.response.use(
	(response) => response,
	(error) => {
		if (error.message === 'Network Error') {
			if (constants.env === 'local') {
				// eslint-disable-next-line no-console
				console.log(error.message);
			} else {
				store.dispatch(push(`/error/NETWORK`));
			}
		} else if (error.response?.status > 401) {
			if (error.response.status === 401) {
				const { data } = error.response;
				const message =
					data.errorList[0].errorMessage ||
					'Something went wrong for which you need to contact upGrad support. Thank you for your patience.';

				store.dispatch(logoutUser(message));
			} else {
				if (constants.env === 'local') {
					// eslint-disable-next-line no-console
					console.log(error.response);
				} else {
					rollbar.error(
						`Backend Error [${error.response.status}] with test session API: `,
						error
					);
					store.dispatch(push(`/error/${error.response.status}`));
				}
			}
		}

		return Promise.reject(error);
	}
);

export default testServiceApiInstance;
