import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as userActions from "actions/user";
import cx from "classnames";
import { ChevronRightIcon } from "icons";
import MenuPreview from "./MenuPreview";
import styles from "./index.scss";

class ProfileMenu extends Component {
	static propTypes = {
		userInfo: PropTypes.object.isRequired,
		userActions: PropTypes.object.isRequired,
		common: PropTypes.object.isRequired
	};

	constructor(...args) {
		super(...args);
		this.state = {
			showMenuPreview: false
		};
	}

	handleToggleProfileMenuClick = () => {
		this.setState({
			showMenuPreview: !this.state.showMenuPreview
		});
	};

	handleCloseProfileMenu = () => {
		this.setState({
			showMenuPreview: false
		});
	};

	render() {
		const { isLoggedIn, firstName, lastName } = this.props.userInfo;
		const { showMenuPreview } = this.state;
		return (
			<div
				className={cx(styles.container, {
					[styles.active]: isLoggedIn
				})}
				onClick={this.handleToggleProfileMenuClick}
			>
				<span className={styles.userName}>
					{`${firstName} ${lastName}`}
				</span>
				<span className={styles.arrowIcon}>
					<ChevronRightIcon />
				</span>
				<div className={styles.profileMenuWrapper}>
					{showMenuPreview ? (
						<MenuPreview onClose={this.handleCloseProfileMenu} />
					) : null}
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		userInfo: state.userInfo,
		common: state.common
	};
}

function mapDispatchToProps(dispatch) {
	return {
		userActions: bindActionCreators(userActions, dispatch)
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ProfileMenu);
