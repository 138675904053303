import React from 'react';
import Icon from './IconBase.js';

const PublishIcon = props => (
	<Icon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g id="PublishIcon">
			<path id="icon/image/flip_24px" fillRule="evenodd" clipRule="evenodd" d="M13 23H11V1H13V23ZM3 19V5C3 3.9 3.9 3 5 3H9V5H5V19H9V21H5C3.9 21 3 20.1 3 19ZM19 9H21V7H19V9ZM15 21H17V19H15V21ZM19 3V5H21C21 3.9 20.1 3 19 3ZM19 17H21V15H19V17ZM17 5H15V3H17V5ZM19 13H21V11H19V13ZM21 19C21 20.1 20.1 21 19 21V19H21Z" fill="black"/>
		</g>
	</Icon>
);

export default PublishIcon;
