import React, {useState, useEffect} from 'react';
import Modal from 'shared/Modal';
import PropTypes from 'prop-types';
import styles from './index.scss'
import { fetchTestData } from  'apis/testServiceApis';
import Input from 'widgets/Input';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { showSnackBar } from 'actions/snackBar';

const TestPreviewModal = ({
    rowInfo,
    isActive,
    closeTestPreview
}) => {

    const [testData, setTestData] = useState({}),
        dispatch = useDispatch();

    const fetchTestDataForPreview = async () => {
        try {
            let {data} = await fetchTestData(rowInfo.id)
            setTestData(data)
        }
        catch (err) {
            dispatch (
                showSnackBar({
                    message: 'Test data fetch failed.',
                    type: 'danger'
                })
            )
            closeTestPreview()
        }
    }

    useEffect(() => {
        fetchTestDataForPreview()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const CreateTestHeader = () => {
        return (
            <div>
                <span>Test Preview</span>
            </div>
        )
    }

    const GetQuestionBlock = (quest) => {
        return (
            <div>
                { quest ?
                    <div className={styles.questionNumber}>
                        <div>
                            <div>
                                <span className={cx(styles.headText, styles.row, styles.marksRight)}>Marks: {quest.question.marks}</span>
                            </div>
                            <div className={styles.row}>
                                <span className={styles.headText}>Question ID: </span>
                                <span>{" #" + quest.question.questionOriginId}</span>
                            </div>
                            <div className={styles.row}>
                                <span className={styles.headText}>Question Title: </span>
                                <span>{" " + quest.question.title}</span>
                            </div>
                            <div className={styles.row}>
                                <span className={styles.headText}>Type:</span>
                                {
                                    quest.question.type.toLowerCase() === 'radio' ?
                                        " SCQ" : quest.question.type.toLowerCase() === 'checkbox' ?
                                            " MCQ" : quest.question.type.toLowerCase() === 'numeric' ?
                                                " NUMERIC" : " CODE"
                                }
                            </div>
                            <div className={styles.row}>
                                <span className={styles.headText}>Description:</span>
                                <div className={styles.questionDescription} dangerouslySetInnerHTML={{ __html: quest.question.description}}></div>
                            </div>
                            <div className={styles.optionList}>
                                {
                                    quest.question.type.toLowerCase() === 'radio' || quest.question.type.toLowerCase() === 'checkbox' ?
                                        quest.question.choices ? quest.question.choices.map((choice, id) => {
                                            return (
                                                <div key={id}>
                                                    <span className={styles.option}>
                                                        <input
                                                            className={styles.optionCheckbox}
                                                            type="checkbox"
                                                            defaultChecked={choice.weightFraction === 1}
                                                            disabled={true}
                                                        />
                                                        <span className={styles.choiceText} dangerouslySetInnerHTML={{ __html: choice.text}}></span>
                                                    </span>
                                                </div>
                                            )
                                        }) : null
                                    : quest.question.type.toLowerCase() === 'numeric' ?
                                    <span className={styles.numeric}>
                                        {(quest.question.numeric.answer === null & quest.question.numeric.minAnswer && quest.question.numeric.maxAnswer) ?
                                            <>
                                                <Input
                                                    type="number"
                                                    className={styles.numericInput}
                                                    value={quest.question.numeric.minAnswer}
                                                    disabled
                                                />
                                                <span className={styles.toText}>to</span>
                                                <Input
                                                    type="number"
                                                    className={styles.numericInput}
                                                    value={quest.question.numeric.maxAnswer}
                                                    disabled
                                                />
                                            </>
                                        : quest.question.numeric.answer ?
                                            <Input
                                                type="number"
                                                className={styles.numericInput}
                                                value={quest.question.numeric.answer}
                                                disabled
                                            />
                                                : null
                                    }
                                    </span>: null
                                }
                            </div>
                        </div>
                    </div>

                : null}
            </div>
        )
    }

    GetQuestionBlock.propTypes = {
        quest: PropTypes.object
    }

    return (
        <>
            <Modal
                HeaderComponent={CreateTestHeader}
                isActive={isActive}
                onClose={closeTestPreview}
                title="Preview Test"
                className={styles.modalWrapper}
            >
                <div className={styles.modalBodyWrapper}>
                    <div className={styles.headerWrapper}>
                        <div className={styles.testName}>
                            {rowInfo.name}
                        </div>
                        <div>
                            <span>Total Questions </span>
                            <Input
                                className={styles.questionCount}
                                value={testData.questionsCount || ''}
                                disabled
                            />
                        </div>
                        <div>
                            <span>Total Marks</span>
                            <Input
                                className={styles.totalMarks}
                                value={testData.marks || ''}
                                disabled
                            />
                        </div>
                    </div>
                    {testData.sections ?
                        <div>
                            {
                                testData.sections.map((section, index) => {
                                    return (
                                        <div key={index}>
                                           { section.questions.map((quest, ind) => {
                                               return (
                                                   <div key={ind}>
                                                        <GetQuestionBlock question={quest}/>
                                                    </div>
                                               )
                                           })}
                                        </div>

                                    )
                                })
                            }
                        </div>

                        : null
                    }
                </div>
            </Modal>
        </>
    )
}

TestPreviewModal.propTypes = {
    rowInfo: PropTypes.object,
    isActive: PropTypes.bool,
    closeTestPreview: PropTypes.func
}

export default TestPreviewModal;