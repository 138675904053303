import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import rollbar from '../../utils/rollbar';

class ErrorBoundary extends Component {
	static propTypes = {
		children: PropTypes.any
	};

	constructor(props) {
		super(props);
		this.state = {
			error: null,
			errorInfo: null
		};
	}

	componentDidCatch(error, errorInfo) {
		// Catch errors in any components below and re-render with error message
		this.setState({
			error: error,
			errorInfo: errorInfo
		});
		rollbar.error('Error with Test Platform Client', {
			error: error,
			errorInfo: errorInfo
		});
		// You can also log error messages to an error reporting service here
	}

	render() {
		if (this.state.errorInfo) {
			return (
				<div className={styles.centerAlign}>
					<img
						src="https://media1.giphy.com/media/TqiwHbFBaZ4ti/giphy.gif"
						alt="Error"
					/>
					<h1>
						<FontAwesomeIcon icon={faExclamationTriangle} />
						We messed up big time. We are working on it.{' '}
						<a href="https://help.upgrad.com/en/">
							Contact Tech support
						</a>{' '}
						for assistance
					</h1>
				</div>
			);
		}
		// Normally, just render children
		return this.props.children;
	}
}

export default ErrorBoundary;
