import testSessionApi from './instances/testSessionApi';
import evaluation from './instances/testEvaluation';

export function getTestActivityDetails(id) {
	const url = `/v2/get_activity_details?guid=${id}`;
	return testSessionApi.get(url);
}

export function startTestSession(id) {
	const url = `/v2/test_session/${id}/start_test`;
	return testSessionApi.get(url);
}

export function submitTestSession(id) {
	const url = `/v2/test_session/${id}/submit_test`;
	return testSessionApi.get(url);
}

export function getTestSession(id) {
	const url = `/v2/test_session/${id}/get_test_data`;
	return testSessionApi.get(url);
}

export function saveAnswer(id, answerPayload) {
	const url = `/v2/test_session/${id}/submit_answer`;
	return testSessionApi.post(url, answerPayload);
}

export function markForReview(id, body) {
	const url = `/v2/test_session/${id}/review_question`;
	return testSessionApi.post(url, body);
}

export function testFeedback(id, body) {
	const url = `/v2/test_session/${id}/submit_user_feeback`;
	return testSessionApi.post(url, body);
}

export function startEvaluation(id) {
	const url = `/v2/manual_evaluation`;
	return evaluation.post(url, {
		guid: id
	});
}

export function getEvaluation(id) {
	const url = `/v2/test_session/${id}/get_evaluation_result`;
	return evaluation.get(url);
}

export function getStudentInvite(params) {
	const url = `/v2/get_user_tests`;
	return testSessionApi.post(url, params);
}

export function getInvitationList({page, limit, id}) {
	const url = `/v2/invitations_list`;
	const testID = parseInt(id);
	return evaluation.post(url, {page, limit, testID});
}

export function triggerManualEvaluation(params) {
	const url = `/v2/manual_evaluation`;
	return evaluation.post(url, params);
}
