import testServiceApiInstance from "./instances/testServiceApiInstance";

export function fetchTestList(params) {
	const url = `/parixa/search`;
	return testServiceApiInstance.get(url, { params });
}

export function closeTest(id) {
	return testServiceApiInstance.post(`/parixa/test/${id}/close`);
}

export function publishTest(id) {
	return testServiceApiInstance.post(`/parixa/test/${id}/publish`);
}

export function archiveTest(id) {
	return testServiceApiInstance.post(`/parixa/test/${id}/archive`);
}

export function unpublishTest(id) {
	return testServiceApiInstance.post(`/parixa/test/${id}/unpublish`);
}

export function createTest(testData) {
	return testServiceApiInstance.post(`/parixa/test`, testData);
}

export function inviteToTest(inviteData) {
	return testServiceApiInstance.post(`/parixa/invite`, inviteData);
}

export function cloneTest(id) {
	return testServiceApiInstance.post(`/parixa/test/${id}/clone`);
}

export function fetchTestData(id) {
	const url = `/parixa/test/${id}`
	return testServiceApiInstance.get(url);
}
