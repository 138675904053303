import React, { useState } from 'react';
import ListingTable from 'shared/ListingTable';
import { getInvitationList, triggerManualEvaluation } from 'apis/testAttemptServiceApis';
import { useParams } from 'react-router-dom';
import {useDispatch} from 'react-redux';
import cx from 'classnames';
import styles from './index.scss';
import Button from 'widgets/Button';
import { DateTime } from 'luxon';
import { showSnackBar } from 'actions/snackBar';

function InvitationListing() {
	const [inviteList, setinviteList] = useState([]),
		[testCount, setTestCount] = useState(0),
		[currPage, setCurrPage] = useState(0),
		[isResultPublished, setResultPublished] = useState(false),
		dispatch = useDispatch(),
		{ tid } = useParams(),
		fetchData = ({ page }) => {
			getInvitationList({ page: page + 1, limit: 5, id: tid })
			.then((res) => {
				var data = res.response;
				setinviteList(data);
				setTestCount(data.length);
				setCurrPage(page);
			})
			.catch(() => {
				dispatch (
					showSnackBar({
						message: "Error",
						type: 'danger'
					})
				)
			});
		};


	const evaluateManually = function(vals) {
		const data = {
			activityId: vals._original.activity_id,
			testID: vals.test_id
		}
		setResultPublished(true)
		return triggerManualEvaluation(data)
			.then(() => {
				setResultPublished(false)
				dispatch(
					showSnackBar({
						message: 'Evaluation triggered successfully.',
						type: 'success'
					})
				)
			})
	}

	return (
		<div className={styles.container}>
			<div className={cx(styles.createTestContainer, 'ph-16')}>
				<h3 className={styles.HeaderTitle}>
					Invitation Listing #{tid}
				</h3>
			</div>
			<div className={cx(styles.listingTableContainer, 'p-40')}>
				<ListingTable
					columns={[
						{ Header: 'Id', accessor: 'test_id', width: 80 },
						{
							Header: 'Test Name',
							accessor: 'test_name',
							width: 200,
							style: {whiteSpace: 'unset'},
						},
						{
							Header: 'Test Marks',
							accessor: 'marks',
							width: 100
						},
						{
							id: 'InvitationAvailability',
							Header: 'Duration',
							width: 80,
							accessor: 'duration'
						},
						{
							id: 'attempts',
							Header: 'Attempts',
							accessor: (row) => (row.startedAttempts + row.submittedAttempts + row.evaluatedAttempts + "/" + row.attempts),
							width: 100
						},
						{
							Header: 'Start time',
							id: 'startTime',
							width: 225,
							accessor: (row) => DateTime.fromISO(row.startTime, {locale: 'en-US'}).toLocaleString(DateTime.DATETIME_SHORT)
						},
						{
							Header: 'End time',
							id: 'endTime',
							width: 225,
							accessor: (row) => DateTime.fromISO(row.endTime, {locale: 'en-US'}).toLocaleString(DateTime.DATETIME_SHORT)
						},
						{
							Header: 'Actions',
							accessor: 'id',
							// eslint-disable-next-line react/display-name
							Cell: (listRow) => <Button disabled={isResultPublished} onClick={() => evaluateManually(listRow.row)}>Publish Result </Button>,
							getProps: () => {
								return {
									style: {
										padding: '13px 5px'
									}
								};
							}
						}
					]}
					page={currPage}
					className={styles.testTable}
					data={inviteList}
					pageSize={10}
					fetchData={fetchData}
					totalRecordCount={testCount}
				/>
			</div>
		</div>
	);
}

export default InvitationListing;
