import actionTypes from 'actionTypes';

export const setLoader = function(payload) {
	return {
		type: actionTypes.SET_LOADING,
		payload
	};
};

export default {
	setLoader
};
