import authApiInstance from "./instances/authApiInstance";
import centralApiInstance from "./instances/centralApiInstance";

export function loginUser({ email, password }) {
	const url = `/apis/auth/v5/login`;
	return authApiInstance.post(url, { username: email, password });
}

export function signUpUser(userDetails) {
	const url = `/apis/v2/signup`;
	return authApiInstance.post(url, userDetails);
}

export function logoutUser() {
	const url = `/apis/logout`;
	return authApiInstance.post(url);
}

export function getUserDetails() {
	const url = `/apis/v2/users/`;
	return centralApiInstance.get(url);
}
