import PropTypes from "prop-types";

function ConditionalRender({ rIf, children }) {
	if (rIf) {
		return children;
	}
	return null;
}

ConditionalRender.propTypes = {
	rIf: PropTypes.any,
	children: PropTypes.any.isRequired
};

export default ConditionalRender;
