import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './index.scss';

const Input = function (props) {
	const handleOnBlur = (event) => {
		const { value } = event.target;
		props.onBlur &&
			props.onBlur({
				event,
				value
			});
	};

	const handleOnChange = (event) => {
		const value = event.target.value;
		const { onChange, name } = props;
		onChange && onChange({ value, name, event });
	};

	const handleKeyPress = (event) => {
		const { name, onEnter } = props;

		if (event.key === 'Enter') {
			onEnter &&
				onEnter({
					name,
					event,
					value: props.value
				});
		}
	};

	const handleFocus = (event) => {
		const { name, onFocus, value } = props;

		// To fix the issue with cursor at beginning
		if (value) {
			event.target.value = '';
			event.target.value = value;
		}

		onFocus &&
			onFocus({
				event,
				name
			});
	};

	const handleKeyDown = (event) => {
		const { name, onKeyDown } = props;
		// prevent e,+ etc., for numerical ip
		if (
			((event.which != 190 &&
				event.which != 8 &&
				event.which != 0 &&
				event.which < 48) ||
				(event.which > 57 && event.which < 190)) &&
			type === 'number'
		) {
			event.preventDefault();
		} else {
			onKeyDown &&
				onKeyDown({
					event,
					name
				});
		}
	};

	const {
		className,
		inputClassName,
		labelClassName,
		type,
		label,
		placeholder,
		defaultValue,
		readOnly,
		multi,
		maxLength,
		autoFocus,
		value,
		accept,
		error,
		onEnter,
		disabled
	} = props;

	const _className = cx(styles.container, className);

	const _inputClassName = cx(
		{
			[styles.input]: !multi,
			[styles.textarea]: multi,
			[styles.readonly]: readOnly,
			[styles.hasError]: error
		},
		inputClassName
	);

	const _labelClassName = cx(styles.label, labelClassName, {
		[styles.error]: error
	});
	const _helperTextClassName = cx(styles.helperText, {
		[styles.error]: error
	});

	let _props = {
		autoFocus,
		disabled,
		placeholder,
		value,
		defaultValue,
		readOnly,
		maxLength,
		className: _inputClassName,
		onChange: handleOnChange,
		onFocus: handleFocus,
		onBlur: handleOnBlur,
		onKeyDown: handleKeyDown,
		name: props.name
	};

	if (onEnter) _props.onKeyPress = handleKeyPress;

	// safari support
	if (type === 'number') {
		_props.inputMode = 'numeric';
		_props.pattern = '[0-9]*';
	}

	return (
		<div className={_className}>
			{label ? <label className={_labelClassName}>{label}</label> : null}

			{multi ? (
				<textarea {..._props}></textarea>
			) : (
				<input {..._props} type={type} accept={accept} />
			)}

			{error && error.length ? (
				<label className={_helperTextClassName}>{error}</label>
			) : null}
		</div>
	);
};

Input.propTypes = {
	name: PropTypes.string,
	accept: PropTypes.string,
	type: PropTypes.oneOf([
		'text',
		'number',
		'password',
		'file',
		'date',
		'email',
		'checkbox'
	]),
	label: PropTypes.string,
	placeholder: PropTypes.string,
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	readOnly: PropTypes.bool,
	autoFocus: PropTypes.bool,
	required: PropTypes.bool,
	maxLength: PropTypes.number,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	onFocus: PropTypes.func,
	onKeyDown: PropTypes.func,
	/* Will be applied to container */
	className: PropTypes.string,
	/* Will be applied to underlying input/textarea tag */
	inputClassName: PropTypes.string,
	/* Will be applied to label */
	labelClassName: PropTypes.string,
	/* Renders a textarea if true */
	multi: PropTypes.bool,
	/* Value */
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	error: PropTypes.string,
	disabled: PropTypes.bool,
	onEnter: PropTypes.func
};

Input.defaultProps = {
	className: '',
	inputClassName: '',
	labelClassName: '',
	type: 'text',
	label: '',
	placeholder: '',
	readOnly: false,
	multi: false,
	disabled: false
};

// eslint-disable-next-line react/display-name
export default Input;
