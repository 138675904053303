// Set up your root reducer here...
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import common from './common';
import userInfo from './userInfo';
import search from './questions';
import snackbar from './snackbar';
import testAttempt from './testAttempt';

export default history =>
	combineReducers({
		router: connectRouter(history),
		common: common,
		userInfo: userInfo,
		search,
		snackbar,
		testAttempt
	});
