import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Modal from 'shared/Modal';
import Input from 'widgets/Input';
import Button from 'widgets/Button';
import styles from './index.scss';
import cx from 'classnames';
import { createTest } from 'apis/testServiceApis';
import { bindActionCreators } from 'redux';
import { showSnackBar } from 'actions/snackBar';
import { setLoader } from 'actions/common';
import { connect } from 'react-redux';

function CreateTestModal({
	closeCreateTestModal,
	updateTestList,
	setLoader,
	showSnackBar,
	isActive
}) {
	const [name, setName] = useState('');
	const [file, setFile] = useState();
	const [duration, setDuration] = useState(0);
	const [errorMessage, setErrorMessage] = useState({});
	const [purpose, setPurpose] = useState('');
	const isFormValid = () => {
		let errors = {};
		if (!file) {
			errors.questionsFile = 'Questions File is required!';
		}
		if (!name || name.trim().length <= 0) {
			errors.testName = 'Name of test is required!';
		}
		if (!purpose || purpose.trim().length <= 0) {
			errors.purpose = 'Purpose of test is required!';
		}
		if (!duration || duration < 0) {
			errors.duration = 'Duration of test should be greater than zero!';
		}
		setErrorMessage(errors);
		return Object.keys(errors)[0] ? false : true;
	};
	const validateAndCreateTest = async () => {
		if (!isFormValid()) return;
		const data = new FormData();
		data.append('file', file);
		data.append('name', name);
		data.append('duration', duration);
		data.append('description', null);
		data.append('purpose', purpose);
		setLoader({ isLoading: true });
		try {
			await createTest(data);
			showSnackBar({
				message: 'Test Creation Successfull!',
				type: 'success'
			});
		} catch (e) {
			showSnackBar({
				message: 'Test Creation Failed!',
				type: 'danger'
			});
		}
		try {
			await updateTestList({ page: 0 });
		} catch (e) {
			showSnackBar({
				message:
					'Test List Update Failed! Please refresh the table to see the updated list.',
				type: 'danger'
			});
		}
		setLoader({ isLoading: false });
		closeCreateTestModal();
	};

	const CreateTestHeader = () => {
		return (
			<div className={cx(styles.createTestModalHeader)}>
				<span>Create Test</span>
			</div>
		);
	};
	const handleFileUpload = ({ event }) => {
		setFile(event.target.files[0]);
	};
	return (
		<Modal
			HeaderComponent={CreateTestHeader}
			isActive={isActive}
			onClose={closeCreateTestModal}
			title="Create a New Test"
		>
			<div className={styles.createTestFormContainer}>
				<form
					onSubmit={e => {
						e.preventDefault();
					}}
				>
					<Input
						type="text"
						name="testName"
						label="Name"
						value={name}
						onChange={({ value }) => setName(value)}
						error={errorMessage['testName']}
						placeholder="Test Name"
					/>
					<Input
						type="text"
						name="testPurpose"
						label="Purpose"
						value={purpose}
						onChange={({ value }) => setPurpose(value)}
						error={errorMessage['purpose']}
						placeholder="Purpose"
						multi
					/>
					<Input
						type="file"
						name="questionsFile"
						onChange={handleFileUpload}
						label="Selected Questions File"
						error={errorMessage['questionsFile']}
					/>
					<div className={styles.downloadSampleFile}>
						<a href="http://cdn.upgrad.com/test-platform/sample-test-creation.csv">
							Download Sample File
						</a>
					</div>

					<Input
						label="Duration (in minutes)"
						type="number"
						name="duration"
						onChange={({ value }) => setDuration(value)}
						value={duration}
						error={errorMessage['duration']}
						accept=".csv"
					/>
					<div className={styles.createBtnWrapper}>
						<Button onClick={validateAndCreateTest}>Create</Button>
					</div>
				</form>
			</div>
		</Modal>
	);
}

CreateTestModal.propTypes = {
	closeCreateTestModal: PropTypes.func,
	updateTestList: PropTypes.func,
	setLoader: PropTypes.func,
	showSnackBar: PropTypes.func,
	isActive: PropTypes.bool
};

function mapDispatchToProps(dispatch) {
	return {
		setLoader: bindActionCreators(setLoader, dispatch),
		showSnackBar: bindActionCreators(showSnackBar, dispatch)
	};
}

export default connect(
	null,
	mapDispatchToProps
)(CreateTestModal);
