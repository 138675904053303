import mapKeys from 'utils/mapKeys';

export default mapKeys([
	'SHOW_RESULTS',
	'SEARCH_BY_KEYWORD',
	'SEARCH_BY_TAG',
	'FETCHING_RESULTS',
	'SET_SEARCH_KEYWORD',
	'SET_SEARCH_TAGS',
	'SET_SEARCH_DIFFICULTY',
	'SET_SEARCH_TYPE',
	'REMOVE_SEARCH_DIFFICULTY',
	'REMOVE_SEARCH_TYPE',
	'REMOVE_SEARCH_TAG',
	'SET_SEARCH_STATUS',
	'REMOVE_SEARCH_STATUS',
	'CLEAR_SEARCH_FILTERS',
	'CLEAR_SEARCH_TAGS',
	'SET_FROM_DATE',
	'SET_TO_DATE',
	'SET_CREATED_BY_USERS'
]);
