import mapKeys from 'utils/mapKeys';

export default mapKeys([
	'SET_QUESTION_LIST',
	'UPDATE_QUESTION_ANSWER',
	'SET_QUESTION_COUNT',
	'SET_ACTIVE_QUESTION',
	'INCREMENT_ACTIVE_QUESTION_ID',
	'DECREMENT_ACTIVE_QUESTION_ID',
	'SET_IS_MARKED_FOR_REVIEW',
	'SET_ANSWER',
	'SET_NUMERIC_ANSWER',
	'SET_AUTO_SAVE_SUCCESS',
	'SET_AUTO_SAVE_PENDING',
	'SET_AUTO_SAVE_FAILURE',
	'SET_TEST_SESSION_DATA',
	'SET_TEST_DATA',
	'SET_SUBTLE_LOADER',
	'START_TEST',
	'END_TEST',
	'SET_TEST_SCORE',
	'CLEAR_STORE',
	'DS_COUNT_SET',
	'SET_FULL_SCREEN_VAL'
]);
