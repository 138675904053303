import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import Header from 'shared/Header';
import SecondaryHeader from 'shared/SecondaryHeader';
import styles from './index.scss';
import SnackBar from 'widgets/SnackBar';
import ConditionalRender from 'shared/ConditionalRender';
import { hideSnackBar } from 'actions/snackBar';
import Loader from 'shared/Loader';

const Layout = ({ secondaryHeader, children }) => {
	const dispatch = useDispatch(),
		{ snackbar, common } = useSelector((s) => s),
		{ message, type } = snackbar,
		_className = cx(common.layoutClassName, styles.rootContainer),
		_headerClassName = cx(common.layoutClassName, styles.headerClassName),
		_contentClassName = cx(
			common.contentClassName,
			styles.contentClassName
		),
		showLoader = common.isLoading,
		hideSnackBarAction = bindActionCreators(hideSnackBar, dispatch),
		handleSnackbarClose = function () {
			hideSnackBarAction();
		},
		[width, setWidth] = React.useState(window.innerWidth),
		handleWindowSizeChange = () => setWidth(window.innerWidth);
	let isMobile = width <= 678;

	React.useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);

		return () =>
			window.removeEventListener('resize', handleWindowSizeChange);
	}, []);

	return isMobile ? (
		<div className={styles.mobileScreenBlocker}>
			Tests Platform is only available on your Desktop Devices 🙌
		</div>
	) : (
		<div className={_className}>
			<Header className={_headerClassName} />
			<SecondaryHeader active={!!secondaryHeader}>
				{secondaryHeader}
			</SecondaryHeader>
			<div className={_contentClassName}>{children}</div>
			<ConditionalRender rIf={!!message}>
				<SnackBar
					message={message}
					type={type}
					onClose={handleSnackbarClose}
				/>
			</ConditionalRender>
			<ConditionalRender rIf={showLoader}>
				<Loader />
			</ConditionalRender>
		</div>
	);
};

Layout.propTypes = {
	children: PropTypes.node,
	secondaryHeader: PropTypes.node
};

export default Layout;
