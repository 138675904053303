import actionTypes from "actionTypes";
import randomString from "utils/randomString";
const initialState = {
	email: "",
	authToken: "",
	firstName: "",
	lastName: "",
	roles: [],
	isLoggedIn: false,
	id: "",
	sessionId: "",
	errorMessage: "",
	loader: false,
	doselect: {}
};

export default function userReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.SET_SESSION_ID:
			return {
				...state,
				sessionId: randomString(32)
			};
		case actionTypes.LOGIN_USER:
			return {
				...state,
				...action.payload,
				isLoggedIn: true
			};
		case actionTypes.SIGNUP_USER:
			return {
				...state,
				...action.payload,
				isLoggedIn: true
			}
		case actionTypes.GET_USER_DETAILS:
			return {
				...state,
				...action.payload,
				isLoggedIn: true
			}
		case actionTypes.LOGOUT_USER:
			return { ...initialState, ...action.payload };
		case actionTypes.RESET_USER:
			return { ...initialState };
	}
	return state;
}
