import React, { lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import TestListPage from 'modules/TestListPage';
import Layout from './layout';
import { getCookie } from './../components/utils/cookies';
import routePaths from './routePaths';
import Loader from './shared/Loader';
import constant from 'constants';
import { getUserDetails } from 'actions/user';
import ErrorComponent from './shared/ErrorPages';
import { allowedUserChecker } from 'utils';
import Offline from 'widgets/offline';

const CreateQuestions = lazy(() => import('modules/CreateQuestions'));
const LoginPage = lazy(() => import('modules/LoginPage'));
const Test = lazy(() => import('modules/Test'));
const QuestionListPage = lazy(() => import('modules/QuestionListPage'));
const Invitee = lazy(() => import('modules/Invitee'));
const SignupPage = lazy(() => import('modules/SignupPage'))

const RouteWrapper = ({ LayoutProp, Component, ...props }) => {
	const isAuthCookiePresent = !!getCookie(
		'upgrad-auth-token.' + constant.env
	);

	let path = useLocation().pathname,
		role = props.userInfo.roles
			? props.userInfo.roles.map((r) => r.role)
			: [],
		access =
			allowedUserChecker(role, props.roles) ||
			props.userInfo.roles.length === 0;
	// access with [] roles is only while loading user info, in reality every user will have a role

	if (isAuthCookiePresent && !access) {
		return <ErrorComponent codeProp={'401'} />;
	} else if (!isAuthCookiePresent) {
		return (
			<Redirect to={`${routePaths.loginPage.route}?redirect=${path}`} />
		);
	}

	return (
		<Route
			{...props}
			render={(routeProps) =>
				LayoutProp ? (
					<LayoutProp>
						<Component {...routeProps} />
					</LayoutProp>
				) : (
					<Component {...routeProps} />
				)
			}
		/>
	);
};

RouteWrapper.propTypes = {
	userInfo: PropTypes.object,
	roles: PropTypes.array,
	getUserDetailsAction: PropTypes.func,
	LayoutProp: PropTypes.any,
	Component: PropTypes.any,
	path: PropTypes.string
};

const RouterComponent = ({ history }) => {
	const { userInfo } = useSelector((s) => s),
		dispatch = useDispatch(),
		getUserDetailsAction = () => {
			dispatch(getUserDetails());
		},
		isAuthCookiePresent = !!getCookie('upgrad-auth-token.' + constant.env);

	React.useEffect(() => {
		setTimeout(function () {
			document.getElementById('pageLoaderRoot').classList.add('hide');
		}, 400);
	}, []);

	React.useEffect(() => {
		if (isAuthCookiePresent && !userInfo.isLoggedIn) {
			getUserDetailsAction();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userInfo.isLoggedIn]);

	if (isAuthCookiePresent && !userInfo.isLoggedIn) {
		<Loader />;
	}

	return (
		<ConnectedRouter history={history}>
			<div>
				<Offline />
				<Suspense fallback={<Loader />}>
					<Switch>
						<Route
							path={routePaths.loginPage.route}
							component={LoginPage}
						/>
						<Route
							path={routePaths.signUp.route}
							component={SignupPage}
						/>
						<Route path="/" exact>
							<HomeRedirect userInfo={userInfo} />
						</Route>
						<Route
							path={routePaths.signUp.route}
							component={SignupPage}
						/>
						<RouteWrapper
							path={routePaths.test.route}
							roles={routePaths.test.roles}
							Component={Test}
							userInfo={userInfo}
						/>
						<RouteWrapper
							exact
							path={routePaths.questions.route}
							roles={routePaths.questions.roles}
							Component={QuestionListPage}
							LayoutProp={Layout}
							userInfo={userInfo}
						/>
						<RouteWrapper
							exact
							path={routePaths.createQuestion.route}
							roles={routePaths.createQuestion.roles}
							LayoutProp={Layout}
							Component={CreateQuestions}
							userInfo={userInfo}
						/>
						<RouteWrapper
							exact
							path={routePaths.editQuestion.route}
							roles={routePaths.editQuestion.roles}
							LayoutProp={Layout}
							Component={CreateQuestions}
							userInfo={userInfo}
						/>
						<RouteWrapper
							path={routePaths.testListPage.route}
							roles={routePaths.testListPage.roles}
							LayoutProp={Layout}
							Component={TestListPage}
							userInfo={userInfo}
						/>
						<RouteWrapper
							path={routePaths.invitation.route}
							roles={routePaths.invitation.roles}
							LayoutProp={Layout}
							Component={Invitee}
							userInfo={userInfo}
						/>
						<Route path="/error/:code">
							<ErrorComponent />
						</Route>
						<Route path="*">
							<ErrorComponent code="404" />
						</Route>
					</Switch>
				</Suspense>
			</div>
		</ConnectedRouter>
	);
};

RouterComponent.propTypes = {
	history: PropTypes.object
};

export default RouterComponent;

const HomeRedirect = ({ userInfo: { isLoggedIn, roles } }) => {
	const dispatch = useDispatch(),
		getUserDetailsAction = () => {
			dispatch(getUserDetails());
		},
		isAuthCookiePresent = !!getCookie('upgrad-auth-token.' + constant.env),
		role = roles.map((r) => r.role);
	if (!isAuthCookiePresent) {
		return <Redirect to={`${routePaths.loginPage.route}?redirect=/`} />;
	} else {
		if (!isLoggedIn) {
			getUserDetailsAction();
		} else {
			if (
				role.indexOf('ROLE_TEACHER') >= 0 ||
				role.indexOf('ROLE_EXTERNAL_SOURCER') >= 0 ||
				role.indexOf('ROLE_ADMIN') >= 0
			) {
				return <Redirect to={`${routePaths.questions.route}`} />;
			} else {
				return <Redirect to={`${routePaths.invitation.route}`} />;
			}
		}
		return <Loader />;
	}
};
