export default function snackBarReducer(
	state = { message: "", type: "" },
	action
) {
	switch (action.type) {
		case "SHOW_SNACKBAR":
			return { ...action.payload };
		case "HIDE_SNACKBAR":
			return { message: "", type: "" };
	}
	return state;
}
