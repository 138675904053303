const allowedUserChecker = (allowed, roles) => {
	for (let role of roles) {
		if (allowed.includes(role)) {
			return true;
		}
	}

	return false;
};

export default allowedUserChecker;
