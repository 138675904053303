import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { NavLink } from "react-router-dom";
import logo from "images/upgrad/tests-logo-icon.svg";
import styles from "./index.scss";

const PrimaryLogo = function({ className }) {
	return (
		<NavLink to="/" className={cx(styles.root, className)}>
			<img className={styles.logo} src={logo} alt="Home" />
			<span className={styles.text}>Sphinx</span>
		</NavLink>
	);
};

PrimaryLogo.propTypes = {
	className: PropTypes.string
};

export default PrimaryLogo;
