import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import PrimaryLogo from 'shared/PrimaryLogo';
import PrimaryNav from 'shared/PrimaryNav';
import ProfileMenu from 'shared/ProfileMenu';
import styles from './index.scss';

const Header = function({ sticky=false }) {
    return (
        <header className={cx(styles.root, {[styles.sticky]: sticky})}>
            <div className={styles.container}>
                <PrimaryLogo />
                <PrimaryNav />
                <ProfileMenu />
            </div>
        </header>
    );
}

Header.propTypes = {
    sticky: PropTypes.bool,
};

export default Header;
