import constants from 'constants';
const setCookie = obj => {
	Object.keys(obj).forEach(key => {
		let domainString =
			constants.env === 'local' ? '' : `domain=${constants.cookieDomain}`;
		document.cookie = `${key}=${obj[key]}; path=/; ${domainString}`;
	});
};

const getCookie = name => {
	let value = '; ' + document.cookie;
	let parts = value.split('; ' + name + '=');
	if (parts.length === 2) {
		return parts
			.pop()
			.split(';')
			.shift();
	}
	return '';
};

let deleteCookie = keys => {
	keys.forEach(k => {
		let domainString =
			constants.env === 'local' ? '' : `domain=${constants.cookieDomain}`;
		document.cookie = `${k}=; Path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT; ${domainString}`;
	});
};

export { setCookie, getCookie, deleteCookie };
