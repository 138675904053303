import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker-cssmodules.min.css';
import styles from './index.scss';
import cx from 'classnames';

function DateRangePicker({
	fromDate,
	toDate,
	onStartDateChange,
	onEndDateChange,
	maxEndDate,
	maxStartDate,
	time
}) {
	const handleStartDateChange = (date) => {
		onStartDateChange && onStartDateChange(date);
	};
	const handleEndDateChange = (date) => {
		onEndDateChange && onEndDateChange(date);
	};
	return (
		<div className={styles.rangeContainer}>
			<div className={styles.ipContainer}>
				<label> From </label>
				<DatePicker
					selected={fromDate}
					onChange={handleStartDateChange}
					selectsStart
					startDate={fromDate}
					endDate={toDate}
					minDate={maxStartDate}
					maxDate={maxEndDate}
					className={cx(styles.startDateInput, 'p-8', 'mb-8')}
					dateFormat={'yyyy-MM-dd hh:mm aa'}
					placeholderText="YYYY-MM-DD"
					showTimeSelect={time}
					timeIntervals={15}
				/>
			</div>
			<div className={styles.ipContainer}>
				<label> To </label>
				<DatePicker
					selected={toDate}
					onChange={handleEndDateChange}
					selectsEnd
					endDate={toDate}
					minDate={fromDate}
					maxDate={maxEndDate}
					dateFormat={'yyyy-MM-dd h:mm aa'}
					className={cx(styles.endDateInput, 'p-8')}
					placeholderText="YYYY-MM-DD"
					showTimeSelect={time}
					timeIntervals={15}
				/>
			</div>
		</div>
	);
}
DateRangePicker.defaultProps = {
	fromDate: null,
	toDate: null
};
DateRangePicker.propTypes = {
	fromDate: PropTypes.instanceOf(Date),
	toDate: PropTypes.instanceOf(Date),
	onStartDateChange: PropTypes.func,
	onEndDateChange: PropTypes.func,
	maxEndDate: PropTypes.instanceOf(Date),
	minStartDate: PropTypes.instanceOf(Date),
	maxStartDate: PropTypes.instanceOf(Date),
	time: PropTypes.bool
};
export default DateRangePicker;
