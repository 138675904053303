import React from 'react';
import { render } from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import Root from './components/Root';
import './sass/index.scss';
import { store, history } from './store';
import ErrorBoundary from './components/shared/ErrorBoundary';

render(
	<AppContainer>
		<ErrorBoundary>
			<Root store={store} history={history} />
		</ErrorBoundary>
	</AppContainer>,
	document.getElementById('app')
);

if (module.hot) {
	module.hot.accept('./components/Root', () => {
		const NewRoot = require('./components/Root').default;
		render(
			<React.StrictMode>
				<AppContainer>
					<NewRoot store={store} history={history} />
				</AppContainer>
			</React.StrictMode>,
			document.getElementById('app')
		);
	});
}
