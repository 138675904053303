import React from "react";
import styles from "./index.scss";

function Loader() {
	return (
		<div className={styles.loaderContainer}>
			<div className={styles.backdrop} />
			<div className={styles.spinnerContainer}>
				<svg className={styles.spinner} viewBox="0 0 50 50">
					<circle
						className={styles.path}
						cx="25"
						cy="25"
						r="20"
						fill="none"
						strokeWidth="5"
					/>
				</svg>
			</div>
		</div>
	);
}
export default Loader;
