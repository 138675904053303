import React, {useState} from 'react';
import styles from './index.scss';
import Actions from 'modules/TestListPage/constants/Actions.js'
import PropTypes from 'prop-types';
import TestPreviewModal from '../../TestPreviewModal';
import ConditionalRender from 'shared/ConditionalRender';

const ActionDropDown = ({cloneTestById, rowData}) => {
    const options = [
        {name: "Please Select", value: Actions.PleaseSelect},
        {name: "Clone", value: Actions.Clone},
        {name: "Preview", value: Actions.Preview}
    ],
    [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false),

    onOptionSelect =  (event) => {
        if(event.currentTarget.value === options[1].value) {
            cloneTestById()
        }
        else if(event.currentTarget.value === options[2].value) {
            openTestPreview(event)
        }
        event.currentTarget.value = options[0].value
    },

    closeTestPreview = () => {
		setIsOpenPreviewModal(false);
	},

   openTestPreview = () => {
		setIsOpenPreviewModal(true);
    }

    return (
        <div className={styles.wrapperDiv}>
            <select
                className={styles.dropdownClone}
                onChange={onOptionSelect}
            >
                {
                    options.map((val, index) => {
                        return <option key={index} value={val.value}>{val.name}</option>
                    })
                }
            </select>
            <ConditionalRender rIf={isOpenPreviewModal}>
				<TestPreviewModal
					rowInfo={rowData}
					isActive={isOpenPreviewModal}
					closeTestPreview={closeTestPreview}
				>
				</TestPreviewModal>
			</ConditionalRender>
        </div>
    )
}

ActionDropDown.propTypes = {
    cloneTestById: PropTypes.func,
    rowData: PropTypes.object
}

export default ActionDropDown;